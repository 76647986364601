/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import styles from '../styles/risks.module.scss';

const RisksPage = () => (
  <Layout>
    <SEO />
    <div className={styles.banner} />
    <div className={styles.container}>
      <div className={`${styles.risksPageContainer} container`}>
        {/* <div className={styles.risksPageContainer}> */}
        <h1 className={styles.title}>
          Risks
        </h1>
        <div className={styles.content}>
          <div className={styles.paragraph}>
            <p>
              Investment in securities of early-stage businesses is accompanied by certain risk factors. Every Patron Investor (“Investors”) should be aware that an investment in such a company (“Brands” or “Brand”) involves a high degree of risk. There can be no assurance that a Brand will achieve its business plan or an Investor will recover or receive a return on their investment.
            </p>
            <br />
            <p>
              The following considerations, among others, should be carefully evaluated before making an investment in a Brand:
            </p>
          </div>
          <br />
          <div className={styles.firstSection}>
            <ol className={styles.listContainer}>
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Loss of Capital: </span>
                  Investments in Brands involve a high degree of risk. The market in which the Brand operates may be highly competitive and it might be unlikely for a Brand to survive and prosper in the long run. There is no guarantee that any Brand will succeed and it is possible that the Investor may lose all the invested capital then see a return on capital or a profit. Therefore, it is very crucial for the Investor to analyze and gauge the risks that are involved with investing in a Brand and shall contemplate and be familiar with any and all shortfalls that may arise including unexpected problems in the areas of product development, manufacturing, marketing, financing, and general management that a company often experiences.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Changing Economic Conditions: </span>
                  The success of any investment activity is determined to a certain extent by general economic conditions. The availability, unavailability or unhindered operation of external credit markets, equity markets, stability in global economies, etc., may have a significant negative impact on a Brand’s operations and profitability. The stability and sustainability of growth in global economies may be impacted by terrorism, acts of war or a variety of other unpredictable events. There can be no assurance that such markets and economic systems will be available or will be available as anticipated or needed for an investment in a Brand to be successful. Changing economic conditions could potentially, and frequently do, adversely impact the revenue of the Brand.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Future and Past Performance: </span>
                  The past performance of a Brand or its management cannot be a means to evaluate its future results. There is no assurance that the targeted results will be achieved. Each Brand’s future statements are based on management&apos;s current expectations and assumptions regarding the Brand’s business and performance, the economy, etc. As with any projection or forecast, forward-looking statements are inherently susceptible to uncertainty and changes in circumstances.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Difficulty in Pricing and Valuing Brand Investments: </span>
                  It is difficult to determine objective valuations for any Brand securities. In addition to the difficulty of determining the magnitude of the risks applicable to a given Brand, there generally will be no readily available secondary market for a Brand’s securities, and hence, an investor’s investments may be difficult to exit before the tenure of the investment.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Lack of Information for Monitoring and Valuing Brands: </span>
                  The Investor may not be able to obtain all necessary information it would want regarding a particular Brand on a timely basis or at all. It is possible that the Investor may not be aware on a timely basis of material adverse changes that have occurred with respect to certain of its investments.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Diverse Investors: </span>
                  Investors in a Brand may have conflicting investment, tax, and other interests with respect to Brand investment, which may arise from the structuring of a Brand investment or other factors. As a consequence, decisions made by the management of the Brand or other Investors on such matters may be more beneficial for some Investors than for others.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>No Control: </span>
                  Investor’s investments will not represent any stake in the Brand. The Investor will not have control over the management of the Brand In such cases, the Investors will be reliant on the existing management and board of directors of such companies, which may include representatives of other financial investors with whom the Investor is not affiliated and whose interests may conflict with the interests of the Investor. Investors in a Brand will not make decisions with respect to the management of the Brand, or other decisions regarding such Brand’s business and affairs.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>No Assurance for Additional Capital: </span>
                  After an Investor has invested in a Brand, continued development and marketing of the Brand’s products or services, or administrative, legal, regulatory or other needs, may require that it obtain additional financing. Such additional financing may not necessarily be available on favourable terms, or at all.
                </p>
              </li>
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Absence of Liquidity: </span>
                  An Investor’s investments will generally be private, illiquid holdings. As such, there may be no readily available liquidity mechanism available for any of the investments. In addition, an investment in a Brand will not be freely transferable, and involves a high degree of risk and low liquidity and should be viewed as aggressive long-term investments. There is no public market for securities in a Brand and it is not expected that a public market will develop. Consequently, an Investor will bear the economic risks of its investment until such time as it holds securities in the Brand. This means that the Investor is likely to see a return only through monthly revenue share repayments.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Withholding and other Taxes: </span>
                  There are many tax risks relating to investments in Brands. The structure of any investment in a Brand may not be tax efficient for any particular Investor, and no Brand guarantees that any particular tax result will be achieved. In addition, tax reporting requirements may be imposed on Investors under the laws of the jurisdictions in which Investors are liable for taxation or in which the Brand is located. Investors should consult their own professional advisors with respect to the tax consequences while investing in a Brand under the laws of the jurisdictions in which the Investors and/or the Brand are liable for taxation.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Regulatory Risks: </span>
                  The investment may be subject to applicable laws of the Investor’s country of residence as well as the country where the relevant Brand is registered. The growth in the revenue of the Brand may depend on the regulatory environment and a change in applicable laws to the Brand or the Investor may adversely affect the returns on the investment, the ability of a Brand to succeed or the ability of an Investor to recover or obtain returns on his investment. The Investors are advised to seek adequate legal advice to familiarize themselves in this relation, prior to making investments in Brands.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  <span className={styles.subTitle}>Confidential Information: </span>
                  Certain information regarding the Brands will be highly confidential. Competitors may benefit from such information if it is ever made public, and that could result in adverse economic consequences to the Investors.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  {/* <span className={styles.subTitle}>Confidential Information: </span> */}
                  The Company providing the present platform is only a facilitator for investment and is a platform offering opportunities for investment purely restricted to investors registered on the platform and is not open to the general public.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  {/* <span className={styles.subTitle}>Confidential Information: </span> */}
                  The Company is not carrying on the business of “Investment Banking”, “Merchant Banking”, “Broking”, “Financial Advisory Services”, “Credit Rating” “Investment Trustee” etc. as regulated by the Securities and Exchange Board of India (SEBI) under the SEBI Act and Rules/ Regulations framed under SEBI Act.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  {/* <span className={styles.subTitle}>Confidential Information: </span> */}
                  The Company through this platform aims to provide Investors with all the requisite information relating to the investment opportunities presented on the platform to enable the Investors to arrive at an informed decision by themselves. The platform does not recommend any investment by itself to the Investors.
                </p>
              </li>
              <br />
              <li className={styles.list}>
                <p className={styles.para}>
                  {/* <span className={styles.subTitle}>Confidential Information: </span> */}
                  All decisions on Investment are to be taken by the Investors on their own independent judgment and the platform does not accept any responsibility of any kind as to the future outcome of the investment which are purely market dependent and the Company / platform does not guarantee any returns on investment.
                </p>
              </li>
            </ol>
          </div>
          {/* <br /> */}
          <p className={styles.thirdSection}>
            The foregoing risks are only indicative in nature. It is recommended that you seek independent legal and tax advice and read the relevant investment documents carefully before investing in a Brand.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default RisksPage;
